import React from "react"
import { Container } from "react-bootstrap"
import Contact from "../components/common/Contact"
import Divider from "../components/common/divider"
import Map from "../components/common/map"
import Layout from "../components/site-wide/layout/layout"
import constants from "../utils/constants"

const AboutPage = () => (
    <Layout
        title={constants.CONTACT.META.TITLE}
    >
        <Container className="mt-5 pt-5">
            <div className="text-center">
                <h1 className="mt-5">Контакти</h1>
                <Divider addClassName="mb-5 green" />
            </div>
            <Contact />
        </Container>

        <Map />
    </Layout>
)

export default AboutPage
